<template>
  <ValidationProvider
      tag="div"
      class="question supported-keyboard column is-half"
      :rules="validationRules"
      v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <template v-if="categories">
          <div :key="index" v-for="(category, index) in categories" class="category-content">
            <p v-if="category.display_title && category.sub_questions.length > 0" class="category-title">{{ category.title[lang] || Object.values(category.title)[0] }}</p>
            <b-field v-for="subQuestion in category.sub_questions" :key="subQuestion.id" class="mb-4">
              <b-checkbox-button
                  class="answer"
                  v-model="selected"
                  :native-value="subQuestion.code"
                  :disabled="loading || disabled.includes(subQuestion.code)"
                  @input="onInputNative(question.qid, subQuestion, $event)"
              >
                <span class="keyboard">{{
                  value[questionKey(question.qid, subQuestion.code)]
                }}</span>
                <span class="option" v-html="subQuestion.question[lang]"></span>
              </b-checkbox-button>
            </b-field>
          </div>
        </template>
        <template v-else>
          <b-field v-for="subQuestion in question.sub_questions" :key="subQuestion.id">
            <b-checkbox-button
                class="answer"
                v-model="selected"
                :native-value="subQuestion.code"
                :disabled="loading || disabled.includes(subQuestion.code)"
                @input="onInputNative(question.qid, subQuestion, $event)"
            >
              <span class="keyboard">{{
                value[questionKey(question.qid, subQuestion.code)]
              }}</span>
              <span class="option" v-html="subQuestion.question[lang]"></span>
            </b-checkbox-button>
          </b-field>
        </template>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
  import { QuestionMixin } from "@/mixins/question";
  import { UnFocusMixin } from "@/mixins/unfocus";
  import { BlockRandomizationMixin } from "@/mixins/block-randomization";
  export default {
    name: "Ranking",
    mixins: [QuestionMixin, UnFocusMixin, BlockRandomizationMixin],
    data() {
      return {
        selected: [],
        disabled: [],
      };
    },
    methods: {
      onInputNative(qid) {
        this.exclusive(this.selected.length === this.qOpts.max);

        const changes = this.question.sub_questions.reduce((acc, curr) => {
          let index = this.selected.indexOf(curr.code);
          return {
            ...acc,
            [this.questionKey(qid, curr.code)]: index >= 0 ? index + 1 : "",
          };
        }, {});

        this.$emit("input", changes);
        this.unFocus();
      },
      exclusive(isExclusive) {
        if (isExclusive) {
          this.disabled = this.question.sub_questions
                  .filter((subQuestion) => !this.selected.includes(subQuestion.code))
                  .map((subQuestion) => subQuestion.code);
        } else {
          this.disabled = [];
        }
        this.isExclusive = isExclusive;
      },
      mapSelected() {
        return Object.keys(this.value)
                .filter(
                        (key) =>
                                key.split("_")[0] === `${this.question.qid}` &&
                                this.value[key] !== ""
                )
                .sort((a, b) => this.value[a] - this.value[b])
                .map((qid) => qid.split("_")[1])
      },
    },
    created() {
      setTimeout(() => {
        this.selected = this.mapSelected() || [];
        this.exclusive(this.selected.length === this.qOpts.max);
      }, 250);
    },
  };
</script>
